import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {}

  error(errorMessage: string) {
    this.toastr.error(errorMessage);
  }

  info(messageTranslationKey: string) {
    this.toastr.info(this.translateService.instant(messageTranslationKey));
  }

  success(messageTranslationKey: string) {
    this.toastr.success(this.translateService.instant(messageTranslationKey));
  }

  successTopCenter(messageTranslationKey: string) {
    this.toastr.success(this.translateService.instant(messageTranslationKey), '', { positionClass: 'toast-top-center' });
  }

  warning(messageTranslationKey: string) {
    this.toastr.warning(this.translateService.instant(messageTranslationKey));
  }
}
